<template>
	<div class="home">  <!--i_pc home_pc-->
		<section class="blog-list-container ">
			<!-- <h1 class="intro-title head">Blogs</h1> -->

			<div class="blog-list-row">
				<div class="blog-list-item" @click="goto(data.link)" v-for="(data,i) in bloglist" :key="i">
					<div class="blog-list-img">
						<img :src="data.poster" alt="">
					</div>
					<div class="blog-list-content">
						<div class="blog-list-title">
							{{ data.title }}
						</div>
						<div class="blog-list-descript">
							{{ data.desc }}
						</div>
						<div class="blog-list-date">
							{{ $global.formatDate(new Date().getTime(),'dd/MM/yyyy') }}
						</div>
					</div>
				</div>

			</div>

		</section>
	</div>
</template>

<script>
// @ is an alias to /src
import "@/css/blog/blogspc.scss";
import "@/css/blog/blogsmobile.scss";

export default {
	name: "blog",
	data() {
		return {
			pageName: "blog",
			bloglist: [
				{
					title: "GB WhatsApp and WhatsApp spy",
					poster: require("@/assets/blog/gb-1.webp"),
					desc: "Many people now use WhatsApp to send videos, photos, audio files, most importantly, photographs. However, as more people use WhatsApp, new needs emerge, such as the need for many accounts to log in at the same time",
					link: "/gbwhatsapp-apk-blog",
				},
				{
					title: "Features of OB WhatsApp",
					poster: require("@/assets/blog/ob-1.png"),
					desc: "	OB WhatsApp is one of the most well-known versions of WhatsApp; this version adds several features that the original WhatsApp lacks, such as stopping others from deleting messages and statuses; it is also ad-free and anti-ban; and you may customize the theme of your WhatsApp.",
					link: "/obwhatsapp-apk",
				},
				{
					title: "What is WhatsApp Plus?",
					poster: require("@/assets/blog/plus-1.png"),
					desc: "	There are numerous WhatsApp Mods available, such as GBWhatsApp, FMWhatsApp, and YoWhatsApp among others. However, let's focus on WhatsApp Plus today and discover its workings, as well as how you can reap its benefits.",
					link: "/whatsapp-plus-apk",
				},
				{
					title: "What is Yo WhatsApp?",
					poster: require("@/assets/blog/yo-3.png"),
					desc: "	If you're seeking for the most recent version of YoWhatsApp APK, you've come to the correct spot. YoWhatsApp's latest version can be downloaded with a single click from our website. We also talk about how to utilise YoWhatsApp and offer tips and ideas. So, if you're interested, keep reading",
					link: "/yowhatsapp-apk",
				},
				{
					title: "GBWhatsApp Temporarily Banned Accounts Issue Fix (Anti-ban)",
					poster: require("@/assets/blog/ban-1.jpg"),
					desc: "Are you using modded WhatsApp-GBWhatsApp? if yes then you must like the features that official WhatsApp doesn't have right? Whatsapp Mods are the modified version of official apps which included some additional features. However, there are some people received the temporarily banned warning from WhatsApp and those users who use it will be ban.",
					link: "/temporarily-banned",
				},
				{
					title: "WhatsApp Channels",
					poster: require("@/assets/blog/blog-plus-2-1.jpg"),
					desc: "Yes, the new Whatsapp function that many people have been waiting for, the channel, is online. It is somewhat similar to WeChat's public account function or much like Telegram Channels, both are used to share information. This is undoubtedly ecstatic for many friends. Now, let's explore WhatsApp channels! This feature can completely change the way you chat and promote your business. In this blog, I'll show you what WhatsApp channels are all about and how to make the most of them easily.",
					link: "/whatsapp-channels",
				},
			],
		};
	},
	mounted() {
		this.$logEvent(`show_gbblogs`);
	},
	methods: {
		goto(url) {
			window.location.href = url;
		},
	},
};
</script>
